import React, { useState, useEffect } from 'react';

import Separator from '~/components/Separator';
import InputSelect from '~/components/Inputs/InputSelect';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { IOptions, TabExtratoProps } from './types';
import { useHistoricoProduto } from '../../HistoricoProdutoContext';
import moment from 'moment';
import { ContainerEntradaSaida, ContainerTableExtrato } from './styles';
import { GridColDef } from '@material-ui/data-grid';
import { DataGridEntradaSaidaCustom } from './components/DataGridEntradaSaidaCustom';
import CustomDataGrid from '../CustomDataGrid';

interface FormProps {
  entradaESaida: boolean;
  label: string;
}

export const TableExtrato: React.FC<FormProps> = (props) => {
  const { entradaESaida, label } = props;
  const {
    extrato,
    handleFormData,
    formData,
    handleTipoCategoria,
    entradaSaida,
    onCallRequestDetalhado,
    titleExtrato,
    columnsAlteracaoPreco,
    columnsExtratoVenda,
    columnsExtratoEstoque,
    isOpenModal,
    handleLoadingExtrato,
    columnsAlteracaoCusto,
    setCallGetHistorico,
    setResetPages,
    resetPage,
    handlePagetExt,
  } = useHistoricoProduto();

  const [tab, setTab] = useState<TabExtratoProps>('PDV');
  const [remAjusteAlturaGrid, setRemAjusteAlturaGrid] = useState<any>('20');
  const [remPaginationAltura, setRemPaginationAltura] = useState<any>('20');

  const selectDay = [
    {
      label: '15 dias',
      value: 15,
    },
    {
      label: '30 dias',
      value: 30,
    },
    {
      label: '60 dias',
      value: 60,
    },
    {
      label: '90 dias',
      value: 90,
    },
    {
      label: '120 dias',
      value: 120,
    },
  ];

  const [tiposCategoriaSelect, setTiposCategoriaSelect] = useState<IOptions>({
    label: '15 dias',
    value: 0,
  });

  const columnsPdv: GridColDef[] = [
    { field: 'id', hide: true },
    {
      field: 'dta_movimento',
      headerName: 'Data Lançamento',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 280,
      hideSortIcons: true,
    },
    {
      field: 'des_operacao',
      headerName: 'Operação',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 310,
      hideSortIcons: true,
    },
    {
      field: 'qtd_total',
      headerName: 'Quantidade',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      width: 270,
      hideSortIcons: true,
    },
  ];

  const columnsFornecedores: GridColDef[] = [
    { field: 'id', hide: true },
    {
      field: 'cfop',
      headerName: 'CFOP',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      hideSortIcons: true,
    },
    {
      field: 'des_tipo_nf',
      headerName: 'Tipo NF',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
      hideSortIcons: true,
    },
    {
      field: 'flg_bonificado',
      headerName: 'Bonif.?',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 110,
      hideSortIcons: true,
    },
    {
      field: 'dta_entrada',
      headerName: 'Data Docto',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 180,
      hideSortIcons: true,
    },
    {
      field: 'qtd_entrada',
      headerName: 'Qtd',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      hideSortIcons: true,
    },
    {
      field: 'num_nf',
      headerName: 'Núm. NF',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 160,
      hideSortIcons: true,
    },
    {
      field: 'des_parceiro',
      headerName: 'Parceiro',
      editable: false,
      headerAlign: 'left',
      align: 'left',
      width: 300,
      hideSortIcons: true,
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data de Inclusão',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 180,
      hideSortIcons: true,
    },
  ];

  const columnsClientes: GridColDef[] = [
    { field: 'id', hide: true },
    {
      field: 'cfop',
      headerName: 'CFOP',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      hideSortIcons: true,
    },
    {
      field: 'des_tipo_nf',
      headerName: 'Tipo NF',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
      hideSortIcons: true,
    },
    {
      field: 'flg_bonificado',
      headerName: 'Bonif.?',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 110,
      hideSortIcons: true,
    },
    {
      field: 'dta_entrada',
      headerName: 'Data Docto',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 180,
      hideSortIcons: true,
    },
    {
      field: 'qtd_entrada',
      headerName: 'Qtd',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      hideSortIcons: true,
    },
    {
      field: 'num_nf',
      headerName: 'Núm. NF',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 160,
      hideSortIcons: true,
    },
    {
      field: 'des_parceiro',
      headerName: 'Parceiro',
      editable: false,
      headerAlign: 'left',
      align: 'left',
      width: 300,
      hideSortIcons: true,
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data de Inclusão',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 180,
      hideSortIcons: true,
    },
  ];

  const columnsAjustes: GridColDef[] = [
    { field: 'id', hide: true },
    {
      field: 'qtd_ajuste',
      headerName: 'Qtd. Ajuste',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 130,
      hideSortIcons: true,
    },
    {
      field: 'dta_ajuste',
      headerName: 'Dta Ajuste',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 130,
      hideSortIcons: true,
    },
    {
      field: 'dta_cadastro',
      headerName: 'Dta Inclusão',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 200,
      hideSortIcons: true,
    },
    {
      field: 'des_ajuste',
      headerName: 'Tipo Ajuste',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 300,
      hideSortIcons: true,
    },
    {
      field: 'num_documento',
      headerName: 'Núm. Docto',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
      hideSortIcons: true,
    },
    {
      field: 'flg_cancelado',
      headerName: 'Cancelado?',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
      hideSortIcons: true,
    },
  ];

  useEffect(() => {
    const newDataIni = moment()
      .subtract(15, 'days')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]');

    handleFormData({
      ...formData,
      dta_ini: newDataIni,
    });

    handleTipoCategoria(15);
    setTiposCategoriaSelect({
      label: '15 dias',
      value: 0,
    });
  }, []);

  const convertPixelToRem = (altura: any) => {
    const fontSizePx = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );

    if (altura) {
      // Calcula a altura em rem
      return altura / fontSizePx;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpenModal) {
        const pagination = document.getElementById('pagination')?.clientHeight;
        const paginationAltura = convertPixelToRem(pagination);
        setRemPaginationAltura(paginationAltura);

        // Seleciona o elemento pelo ID
        const modalHeader = document.getElementById('modalHeader');

        // Obtém o elemento pai
        const modal = modalHeader?.parentElement;
        let ModalAltura = modal?.clientHeight;

        if (ModalAltura) {
          ModalAltura -= 43;
        }

        const alturaRemModal = convertPixelToRem(ModalAltura);

        const containerSeparadorTableExtratoAltura = document.getElementById(
          'containerSeparadorTableExtrato',
        )?.clientHeight;
        const alturaRemContainerSeparadorTableExtrato = convertPixelToRem(
          containerSeparadorTableExtratoAltura,
        );

        const containerInfoProdutoAltura = document.getElementById(
          'ContainerInfoProduto',
        )?.clientHeight;

        const alturaRemContainerInfoProduto = convertPixelToRem(
          containerInfoProdutoAltura,
        );

        // Faz calculo para delimitar altura do grid de acordo com a altura do modal
        if (
          alturaRemContainerInfoProduto &&
          alturaRemContainerSeparadorTableExtrato &&
          alturaRemModal
        ) {
          setRemAjusteAlturaGrid(
            alturaRemModal -
              (alturaRemContainerInfoProduto +
                alturaRemContainerSeparadorTableExtrato),
          );
        }
      }
    }, 100);
  }, [isOpenModal]);

  return (
    <ContainerTableExtrato>
      <Row id="containerSeparadorTableExtrato">
        <Col md={9} sm={12}>
          <Separator labelText={label} childrenWidth="160px" />
        </Col>
        <Col
          style={{
            position: 'relative',
            zIndex: 9999,
          }}
          md={3}
          sm={12}
        >
          <InputSelect
            options={selectDay}
            label=""
            value={tiposCategoriaSelect}
            onChange={(value: any) => {
              handlePagetExt(1);
              setResetPages(true);
              const newDataIni = moment()
                .subtract(value.value, 'days')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]');

              handleFormData({
                ...formData,
                dta_ini: newDataIni,
              });
              onCallRequestDetalhado(true);
              setCallGetHistorico(true);
              handleLoadingExtrato(true);
              handleTipoCategoria(value.value);
              setTiposCategoriaSelect({
                label: value.label,
                value: value.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <div>
          {!entradaESaida && titleExtrato === 'tabelaCusto' && (
            <div>
              <CustomDataGrid
                rows={extrato}
                columns={columnsAlteracaoCusto}
                minHeight={`${remAjusteAlturaGrid - remPaginationAltura}rem`}
                maxHeight={`${remAjusteAlturaGrid - remPaginationAltura}rem`}
                resetPage={resetPage}
              />
            </div>
          )}
          {!entradaESaida && titleExtrato === 'tabelaPreco' && (
            <div>
              <CustomDataGrid
                rows={extrato}
                columns={columnsAlteracaoPreco}
                minHeight={`${remAjusteAlturaGrid - remPaginationAltura}rem`}
                maxHeight={`${remAjusteAlturaGrid - remPaginationAltura}rem`}
                resetPage={resetPage}
              />
            </div>
          )}

          {!entradaESaida && titleExtrato === 'venda' && (
            <CustomDataGrid
              minHeight={`${remAjusteAlturaGrid}rem`}
              maxHeight={`${remAjusteAlturaGrid}rem`}
              columns={columnsExtratoVenda}
              rows={extrato}
              resetPage={resetPage}
              totalizador
            />
          )}
          {!entradaESaida && titleExtrato === 'estoque' && (
            <CustomDataGrid
              rows={extrato}
              columns={columnsExtratoEstoque}
              minHeight={`${remAjusteAlturaGrid - remPaginationAltura}rem`}
              maxHeight={`${remAjusteAlturaGrid - remPaginationAltura}rem`}
              resetPage={resetPage}
            />
          )}
          {entradaESaida && (
            <ContainerEntradaSaida>
              <Tabs
                id="controlled-entrada-e-saida"
                activeKey={tab}
                onSelect={(ev) => {
                  const tabSelected = ev as TabExtratoProps;
                  setTab(tabSelected);
                }}
              >
                <Tab
                  style={{ marginTop: '0.625rem' }}
                  eventKey="PDV"
                  title="PDV"
                >
                  <DataGridEntradaSaidaCustom
                    rows={entradaSaida.pdv_agg}
                    columns={columnsPdv}
                    height={`${remAjusteAlturaGrid - 3}rem`}
                    hidePaginacao
                  />
                </Tab>
                <Tab
                  style={{ marginTop: '0.625rem' }}
                  eventKey="Fornecedores"
                  title="Fornecedores"
                >
                  <DataGridEntradaSaidaCustom
                    rows={entradaSaida.fornecedor_agg}
                    columns={columnsFornecedores}
                    height={`${remAjusteAlturaGrid - 3}rem`}
                  />
                </Tab>
                <Tab
                  style={{ marginTop: '0.625rem' }}
                  eventKey="Clientes"
                  title="Clientes"
                >
                  <DataGridEntradaSaidaCustom
                    rows={entradaSaida.cliente_agg}
                    columns={columnsClientes}
                    height={`${remAjusteAlturaGrid - 3}rem`}
                  />
                </Tab>
                <Tab
                  style={{ marginTop: '0.625rem' }}
                  eventKey="Ajustes"
                  title="Ajustes"
                >
                  <DataGridEntradaSaidaCustom
                    rows={entradaSaida.ajustes_agg}
                    columns={columnsAjustes}
                    height={`${remAjusteAlturaGrid - 3}rem`}
                  />
                </Tab>
              </Tabs>
            </ContainerEntradaSaida>
          )}
        </div>
      </Row>
    </ContainerTableExtrato>
  );
};
